import { CreateApplicationPayload, TotalPremiums } from '@breathelife/types';

import { Application } from '../Models/Application';
import ApiService from './ApiService';

export type LaunchAssistedApplicationPayload = Partial<Pick<Application, 'coverageAmount' | 'product'>>;
export type UpdateAssistedApplicationPayload = Required<Pick<Application, 'answers' | 'answersV2'>>;

export async function launchNewAssistedApplication(data: CreateApplicationPayload): Promise<Application> {
  const response = await ApiService.launchNewAssistedApplication<Application>(data);
  return response.data;
}

export async function launchAssistedApplication(
  applicationId: string,
  data: LaunchAssistedApplicationPayload,
): Promise<Application> {
  const response = await ApiService.launchAssistedApplication<Application>(applicationId, data);
  return response.data;
}

export async function updateAssistedApplicationAnswers(
  applicationId: string,
  data: UpdateAssistedApplicationPayload,
): Promise<Application> {
  const response = await ApiService.updateAssistedApplicationAnswers<Application>(applicationId, data);
  return response.data;
}

export async function getProductsWidgetTotalPremiums(applicationId: string): Promise<TotalPremiums | null> {
  const response = await ApiService.getProductsWidgetTotalPremiums(applicationId);
  return response.data;
}
