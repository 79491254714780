import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { ESignSigner2FAInfo, ESignSignerAuthMethod } from '@breathelife/types';

import { ESignatureContext } from '../../../../Context/ESignatureContext';
import { getFormValidationError } from '../../../../Helpers/inputValidation/form/signer';
import { useCarrierContext, useDispatch, useNavigation } from '../../../../Hooks';
import { RequiredFile } from '../../../../Hooks/Application';
import { useGetApplicationQuery } from '../../../../ReactQuery/Application/application.queries';
import { useProcessParticipantsQuery } from '../../../../ReactQuery/ParticipantProcessor/participantProcessor.queries';
import { notificationSlice } from '../../../../ReduxStore/Notification/NotificationSlice';
import { CancelESignatureModal } from '../../Modals/ESignature/CancelESignatureModal';
import { SendESignatureRequestModal } from '../../Modals/ESignature/SendESignatureRequestModal';
import { ESignatureDetailsView } from './ESignatureDetailsView';

type ESignatureDetailsContainerProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (signers: ESignSigner2FAInfo[]) => void;
  requiredFiles: RequiredFile[];
};

export function ESignatureDetailsContainer({
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  requiredFiles,
}: ESignatureDetailsContainerProps): ReactElement {
  const { features } = useCarrierContext();
  const { applicationId } = useNavigation();
  const { data: application } = useGetApplicationQuery(applicationId);

  const dispatch = useDispatch();

  const enabledAuthMethods = features.eSignature.enabledTwoFactorAuthenticationTypes;
  const defaultAuthMethod = enabledAuthMethods?.[0] || ESignSignerAuthMethod.cellphone;

  const [signers, setSigners] = useState<ESignSigner2FAInfo[]>([]);
  const [isSendRequestModalOpen, setIsSendRequestModalOpen] = useState(false);
  const [isCancelESignatureModalOpen, setIsCancelESignatureModalOpen] = useState(false);

  const { data: eSignSigners, isLoading: isParticipantsLoading } = useProcessParticipantsQuery(application?.id, {
    enabled: isOpen && application !== undefined,
  });

  const formError = useMemo(
    () => getFormValidationError(signers, features.eSignature?.enforceUniqueEmail),
    [signers, features.eSignature?.enforceUniqueEmail],
  );

  const onCloseSendRequestModal = useCallback(() => {
    setIsSendRequestModalOpen(false);
  }, []);

  const onUpdateSigner = useCallback((updatedSigner: ESignSigner2FAInfo, updatedSignerIdx: number) => {
    setSigners((signers) =>
      signers.map((signer, idx) => {
        if (idx === updatedSignerIdx) return updatedSigner;
        return signer;
      }),
    );
  }, []);

  const onClickSend = useCallback(() => {
    if (formError) {
      dispatch(
        notificationSlice.actions.setError({
          message: formError.message,
        }),
      );
    } else {
      setIsSendRequestModalOpen(true);
    }
  }, [dispatch, formError]);

  const onSendSignatureRequest = useCallback(async () => {
    setIsSendRequestModalOpen(false);
    onSubmit(signers);
  }, [onSubmit, signers]);

  const onClickCancel = useCallback(async () => {
    setIsCancelESignatureModalOpen(true);
  }, []);

  const ENABLE_DECOUPLE_ESIGN_FLOW = !!features.enableDecoupleESignFlow?.enabled;

  const onCloseCancelESignatureModal = useCallback(() => {
    setIsCancelESignatureModalOpen(false);

    // Close the ESignature drawer when we cancel the ceremony
    if (ENABLE_DECOUPLE_ESIGN_FLOW) {
      onClose();
    }
  }, [ENABLE_DECOUPLE_ESIGN_FLOW, onClose]);

  useEffect(() => {
    if (eSignSigners) {
      setSigners(
        eSignSigners.map((signer) => {
          return { ...signer, authMethod: signer.authMethod ?? defaultAuthMethod };
        }),
      );
    }
  }, [eSignSigners, defaultAuthMethod]);

  return (
    <ESignatureContext.Provider value={{ signers, setSigners, application }}>
      <ESignatureDetailsView
        isLoading={isLoading || isParticipantsLoading}
        isOpen={isOpen}
        onClose={onClose}
        onClickSend={onClickSend}
        onClickCancel={onClickCancel}
        onUpdateSigner={onUpdateSigner}
        requiredFiles={requiredFiles}
        participants={signers}
      />
      <SendESignatureRequestModal
        isOpen={isSendRequestModalOpen}
        onClose={onCloseSendRequestModal}
        onConfirm={onSendSignatureRequest}
      />
      <CancelESignatureModal isOpen={isCancelESignatureModalOpen} onClose={onCloseCancelESignatureModal} />
    </ESignatureContext.Provider>
  );
}
