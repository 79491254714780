import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@breathelife/types';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { useDeleteUserMutation } from '../../../../ReactQuery/Admin/Users/users.mutations';

type Props = {
  user: User;
  isOpen: boolean;
  closeModal: () => void;
};

export function ConfirmDeleteModal(props: Props): ReactElement {
  const { t } = useTranslation();

  const deleteUserMutation = useDeleteUserMutation(props.user);
  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.confirmDeleteUser.title')}
      isOpen={props.isOpen}
      closeModal={() => props.closeModal()}
      submitButton={
        <SubmitButton
          isLoading={deleteUserMutation.isLoading}
          disabled={deleteUserMutation.isLoading}
          onClick={() => deleteUserMutation.mutate()}
        >
          {t('cta.delete')}
        </SubmitButton>
      }
    >
      <Typography variant='body1' grey={60}>
        {t('modals.confirmDeleteUser.confirmDelete')}
      </Typography>
      <Box my={2}>
        <Typography variant='h3' component='span' grey={100}>
          {props.user.firstName} {props.user.lastName}
        </Typography>
        <Typography variant='body1' grey={100}>{`<${props.user.emailLogin}>`}</Typography>
      </Box>
    </ModalLayout>
  );
}
