import _ from 'lodash';
import { ElementType, ReactElement, ReactNode } from 'react';

import { Typography as TypographyMui, TypographyProps } from '@breathelife/mui';
import { FullColorGradient } from '@breathelife/types';

import styled, { css } from '../../Styles/themed-styled-components';
import { TypographyVariant } from '../../Styles/Types';

type TextProps = { grey?: keyof FullColorGradient };

export const TextColorWrapper = styled.span<TextProps>`
  ${(props) =>
    !_.isUndefined(props.grey) &&
    css`
      color: ${props.theme.colors.grey[props.grey]};
    `}

  // Override grey color if there is a color specified
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const Body1 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.body1.size};
    font-weight: ${(props) => props.theme.text.body1.weight};
  }
`;

export const Body2 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.body2.size};
    font-weight: ${(props) => props.theme.text.body2.weight};
  }
`;

export const Body3 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.body3.size};
    font-weight: ${(props) => props.theme.text.body3.weight};
  }
`;

export const Body4 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.body4.size};
    font-weight: ${(props) => props.theme.text.body4.weight};
  }
`;

export const Body5 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.body5.size};
    font-weight: ${(props) => props.theme.text.body5.weight};
  }
`;

export const Small1 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.small1.size};
    font-weight: ${(props) => props.theme.text.small1.weight};
  }
`;

export const Small2 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.small2.size};
    font-weight: ${(props) => props.theme.text.small2.weight};
  }
`;

export const Small3 = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.small3.size};
    font-weight: ${(props) => props.theme.text.small3.weight};
  }
`;

export const ButtonText = styled(TextColorWrapper)`
  &&& {
    font-size: ${(props) => props.theme.text.button.size};
    font-weight: ${(props) => props.theme.text.button.weight};
  }
`;

type Props = {
  className?: string;
  children?: ReactNode;
  variant: TypographyVariant;
  grey?: keyof FullColorGradient;
  color?: string;
} & Omit<TypographyProps<ElementType, { component?: string }>, 'variant' | 'color'>;

function Typography({ variant, color, ...props }: Props): ReactElement {
  let Component;

  switch (variant) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
      return (
        <TypographyMui {...props} variant={variant}>
          <TextColorWrapper grey={props.grey} color={color}>
            {props.children}
          </TextColorWrapper>
        </TypographyMui>
      );
    case 'body1':
      Component = (
        <TypographyMui {...props}>
          <Body1 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Body1>
        </TypographyMui>
      );
      break;
    case 'body2':
      Component = (
        <TypographyMui {...props}>
          <Body2 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Body2>
        </TypographyMui>
      );
      break;
    case 'body3':
      Component = (
        <TypographyMui {...props}>
          <Body3 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Body3>
        </TypographyMui>
      );
      break;
    case 'body4':
      Component = (
        <TypographyMui {...props}>
          <Body4 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Body4>
        </TypographyMui>
      );
      break;
    case 'body5':
      Component = (
        <TypographyMui {...props}>
          <Body5 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Body5>
        </TypographyMui>
      );
      break;
    case 'small1':
      Component = (
        <TypographyMui {...props}>
          <Small1 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Small1>
        </TypographyMui>
      );
      break;
    case 'small2':
      Component = (
        <TypographyMui {...props}>
          <Small2 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Small2>
        </TypographyMui>
      );
      break;
    case 'small3':
      Component = (
        <TypographyMui {...props}>
          <Small3 className={props.className} grey={props.grey} color={color}>
            {props.children}
          </Small3>
        </TypographyMui>
      );
      break;
    case 'button':
      Component = (
        <TypographyMui {...props}>
          <ButtonText className={props.className} grey={props.grey} color={color}>
            {props.children}
          </ButtonText>
        </TypographyMui>
      );
      break;
  }

  return Component;
}

export const ItalicTypography = styled(Typography)`
  font-style: italic;
`;

export const MonospaceTypography = styled(Typography)`
  && {
    font-family: Courier, Monaco, monospace !important;
    && span {
      font-family: Courier, Monaco, monospace !important;
    }
  }
`;

export function Strikeable(props: { strike?: boolean; children: ReactElement }): ReactElement {
  const { strike, children } = props;
  return strike ? <s>{children}</s> : children;
}

export default Typography;
