import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../Styles/themed-styled-components';

import { ESignCeremonyStatus, Permission, User } from '@breathelife/types';

import { DropdownButton } from '../../../Components/Button/DropdownButton';
import { SearchTextField } from '../../../Components/TextInputs';
import { Select, SelectProps } from '../../../Components/Select/Select';
import Typography from '../../../Components/Typography';
import { userHasPermission } from '../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../Hooks';
import { ModalType } from '../../../Models/Layout';
import { DefaultLeadsListFilterIds } from '../../../Pages/Home/Modals/UserListModal/UserListModal';
import { ModalPayload } from '../../../ReduxStore/Layout/LayoutSlice';
import { useUsers } from '../../../ReactQuery/Admin/Users/users.queries';
import { LeadSignatureStatusesColumnData, LeadStatusesColumnData } from '../../../Models/Lead';

const FilterContainer = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: flex-end;
`;

const StyledDropdownButton = styled(DropdownButton)`
  &&&& {
    padding: 0px 12px !important;
    width: 160px;
    margin-left: 16px;
    border-radius: 4px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    margin-left: 20px;
  }
` as <T extends string | number = string>(props: SelectProps<T>) => ReactElement;

export type Filters = {
  selectedUserId?: string;
  signatureStatus?: string;
  status?: string;
};

type Props = {
  onFiltersChanged: (filters?: Filters) => void;
  onSearchChange: (searchString: string) => void;
  setModalState: (state: ModalPayload) => void;
  selectedUserId: string;
  searchTerm?: string;
  usedStatuses: LeadStatusesColumnData;
  usedSignatureStatuses: LeadSignatureStatusesColumnData;
  currentFilters: Filters;
};

export function ListFilter(props: Props): ReactElement {
  const {
    onFiltersChanged,
    onSearchChange,
    setModalState,
    selectedUserId,
    searchTerm,
    usedStatuses,
    usedSignatureStatuses,
    currentFilters,
  } = props;
  const { t } = useTranslation();
  const { leadTableColumns } = useCarrierContext();
  const [applicationStatusFilterValue, setApplicationStatusFilterValue] = useState('');
  const [signatureStatusFilterValue, setSignatureStatusFilterValue] = useState('');
  const { data: users } = useUsers();
  const currentUser = useSelector((store) => store.leadPlatform.authentication.user) as User;

  const selectedUser = useMemo(() => {
    return users?.data.find((user) => user.auth0Id === selectedUserId);
  }, [users, selectedUserId]);

  const hasSignatureStatusColumn = useMemo(() => {
    return leadTableColumns.some((column) => {
      return column.name === 'signatureStatus';
    });
  }, [leadTableColumns]);

  const leadsListFilterByUser = useMemo(() => {
    let title;
    if (!selectedUser) {
      title =
        selectedUserId === DefaultLeadsListFilterIds.All
          ? t('modals.leadsListFilters.all')
          : t('modals.leadsListFilters.unassigned');
    } else if (selectedUser.auth0Id === currentUser.auth0Id) {
      title = t('modals.leadsListFilters.me');
    } else {
      title = `${selectedUser.firstName} ${selectedUser.lastName}`;
    }
    return title;
  }, [selectedUser, selectedUserId, currentUser, t]);

  const applicationStatuses = useMemo(
    () =>
      Object.entries(usedStatuses).map((status) => {
        const [key, value] = status;
        return {
          value: key,
          // They are provided by a carrier with a value, then saved to the store
          // Typescript thinks that the specific status could be undefined
          label: t(`leadStatuses.${value.label}`),
        };
      }),
    [t, usedStatuses],
  );

  const signatureStatuses = useMemo(
    () =>
      Object.entries(usedSignatureStatuses)
        .filter((status) => {
          return ![ESignCeremonyStatus.DRAFT, ESignCeremonyStatus.READY, ESignCeremonyStatus.SENT].includes(
            status[0] as ESignCeremonyStatus,
          );
        })
        .map((status) => {
          const [key, value] = status;
          return {
            value: key,
            label: t(`eSignature.ceremonyStatus.${value}`),
          };
        }),
    [t, usedSignatureStatuses],
  );

  useEffect(() => {
    setApplicationStatusFilterValue(currentFilters.status || '');
  }, [currentFilters.status]);

  useEffect(() => {
    setApplicationStatusFilterValue(currentFilters.signatureStatus || '');
  }, [currentFilters.signatureStatus]);

  const emptyOption = [{ value: '', label: t('leadsListTable.status') }];
  const applicationStatusOptions = emptyOption.concat(applicationStatuses);
  const signatureStatusOptions = emptyOption.concat(_.compact(signatureStatuses));

  const onApplicationStatusFilterChange = useCallback(
    (value: string) => {
      setApplicationStatusFilterValue(value);
      const filterValue = { status: !_.isEmpty(value) ? value : undefined };
      onFiltersChanged(filterValue);
    },
    [onFiltersChanged],
  );

  const onSignatureStatusFilterChange = useCallback(
    (value: string) => {
      setSignatureStatusFilterValue(value);
      const filterValue = { signatureStatus: !_.isEmpty(value) ? value : undefined };
      onFiltersChanged(filterValue);
    },
    [onFiltersChanged],
  );

  const onLeadsListFilterClick = useCallback(() => {
    setModalState({ modalState: { isOpen: true, type: ModalType.leadsListFilter } });
  }, [setModalState]);

  const canSeeOtherLeads = userHasPermission(currentUser?.permissions, [
    Permission.LeadReadGroup,
    Permission.LeadReadAll,
  ]);

  return (
    <FilterContainer>
      <SearchTextField onSearchChange={onSearchChange} defaultValue={searchTerm} />
      <StyledSelect
        isSearchFilter
        id='status-select'
        label={t('leadsListTable.applicationStatus')}
        options={applicationStatusOptions}
        value={applicationStatusFilterValue}
        onChange={onApplicationStatusFilterChange}
      />
      {hasSignatureStatusColumn && (
        <StyledSelect
          isSearchFilter
          id='signature-status-select'
          label={t('leadsListTable.signatureStatus')}
          options={signatureStatusOptions}
          value={signatureStatusFilterValue}
          onChange={onSignatureStatusFilterChange}
        />
      )}
      {canSeeOtherLeads && (
        <StyledDropdownButton id='assigned-to-select' onClick={onLeadsListFilterClick}>
          <Typography variant='body1'>{leadsListFilterByUser}</Typography>
        </StyledDropdownButton>
      )}
    </FilterContainer>
  );
}
