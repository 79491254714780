import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { StoredFile, FileTemplate } from '@breathelife/types';

import { Tabs, TabsContainer, TabType } from '../../../../Components/Tabs/Tabs';
import { RequiredFile } from '../../../../Hooks/Application';
import { Application } from '../../../../Models/Application';
import { OtherFiles } from './Files/OtherFiles';
import { RequiredFiles } from './Files/RequiredFiles';

type DocumentDrawerBodyProps = {
  application: Application;
  ErrorAlert?: ReactElement;
  isUploadDisabled: boolean;
  isUploading: boolean;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
  onFileUpload: (file: File, template?: FileTemplate) => void;
  onTabChange?: (newTabIndex?: number) => void;
  otherFiles: StoredFile[];
  requiredFiles: RequiredFile[];
};

export function DocumentDrawerBody(props: DocumentDrawerBodyProps): ReactElement | null {
  const { t } = useTranslation();
  const {
    application,
    ErrorAlert,
    isUploadDisabled,
    isUploading,
    onFileDelete,
    onFileUpload,
    onTabChange,
    otherFiles,
    requiredFiles,
  } = props;

  const [tabValue, setTabValue] = useState<number>(0);
  const handleTabChange = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
      onTabChange?.(newValue);
    },
    [onTabChange],
  );

  const tabs: TabType[] = [
    t('assistedApplication.documentsDrawer.requiredFiles'),
    t('assistedApplication.documentsDrawer.otherFiles'),
  ].map((label) => ({
    disabled: isUploading,
    label,
  }));

  return (
    <Box>
      <TabsContainer borderBottom={1} borderTop={1}>
        <Box px={4}>
          <Tabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        </Box>
      </TabsContainer>

      <Box py={2}>
        {ErrorAlert}
        {tabValue === 0 && (
          <Box px={4}>
            <RequiredFiles
              application={application}
              files={requiredFiles}
              isUploading={isUploading}
              onFileUpload={onFileUpload}
              onFileDelete={onFileDelete}
            />
          </Box>
        )}
        {tabValue === 1 && (
          <OtherFiles
            application={application}
            files={otherFiles}
            isUploading={isUploading}
            isUploadDisabled={isUploadDisabled}
            onFileUpload={onFileUpload}
            onFileDelete={onFileDelete}
          />
        )}
      </Box>
    </Box>
  );
}
