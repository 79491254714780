import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ListItemText } from '@breathelife/mui';
import { FileTemplate, Permission, StoredFile } from '@breathelife/types';
import { ImageTypes, Loader } from '@breathelife/ui-components';

import { IconButton } from '../../../../../../Components/Controls';
import { Icon } from '../../../../../../Components/Icons';
import Typography from '../../../../../../Components/Typography';
import { printFileSize } from '../../../../../../Helpers/fileSize';
import { useGetApplicationFile, useLocale } from '../../../../../../Hooks';
import { Application } from '../../../../../../Models/Application';
import { RestrictedToUserPermissions } from '../../../../../Restricted/RestrictedToUserPermissions';
import { DisplayNameTypography, SubtitleContainer } from '../Styles';

type CompletedRequiredFileProps = {
  application: Application;
  template: FileTemplate;
  storedFile: StoredFile;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function CompletedRequiredFile(props: CompletedRequiredFileProps): ReactElement {
  const { application, template, storedFile, onFileDelete: onFileDeleteProp } = props;

  const platformLanguage = useLocale();
  const { t } = useTranslation();
  //  Display file name according to application language
  //  if translation does not exit: default to english name
  const localizedFileName = template.localizedName[platformLanguage] ?? template.localizedName.en;

  const { fetchFile, fileToView } = useGetApplicationFile(
    application.id,
    storedFile.id,
    useCallback(({ signedUrl }) => {
      window.open(signedUrl, '_blank')?.focus();
    }, []),
  );

  const sizeLabel = useMemo(
    () => (storedFile.sizeBytes ? printFileSize(storedFile.sizeBytes, 1) : undefined),
    [storedFile.sizeBytes],
  );

  const onFileDelete = useCallback(async () => {
    await onFileDeleteProp(storedFile.id, true);
  }, [onFileDeleteProp, storedFile.id]);

  const isLoading = fileToView.isLoading;

  return (
    <ListItemText>
      <Box display='flex' flexDirection='row' my={3}>
        <Box mr={2}>
          {isLoading ? (
            <Loader size='24' />
          ) : (
            <Icon
              name='success'
              variant='green'
              inverted
              imageType={ImageTypes.meaningful}
              ariaLabel={t('assistedApplication.success')}
            />
          )}
        </Box>
        <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between'>
          <div>
            <div>
              <DisplayNameTypography variant='h4'>{localizedFileName}</DisplayNameTypography>
            </div>
            <div>
              <DisplayNameTypography variant='body3'>{storedFile.displayName}</DisplayNameTypography>
            </div>
            {sizeLabel && (
              <div>
                <SubtitleContainer>
                  <Typography variant='body3'>{sizeLabel}</Typography>
                </SubtitleContainer>
              </div>
            )}
          </div>
          <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'center' }}>
            <Box mx={1}>
              <IconButton
                icon={
                  <Icon
                    name='eye'
                    imageType={ImageTypes.meaningful}
                    ariaLabel={t('modals.assistedApplication.fileAttachment.openAttachment')}
                  />
                }
                disabled={isLoading}
                onClick={fetchFile}
              />
            </Box>
            {!application.submitted && (
              <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRemove]}>
                <Box>
                  <IconButton
                    icon={
                      <Icon
                        name='delete'
                        imageType={ImageTypes.meaningful}
                        ariaLabel={t('modals.assistedApplication.fileAttachment.deleteAttachment')}
                      />
                    }
                    disabled={isLoading}
                    onClick={onFileDelete}
                  />
                </Box>
              </RestrictedToUserPermissions>
            )}
          </Box>
        </Box>
      </Box>
    </ListItemText>
  );
}
