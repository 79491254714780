import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { User, UserStatus } from '@breathelife/types';

import { Icon } from '../../../../Components/Icons';
import { MoreActionButton } from '../../../../Components/MoreActionButton/MoreActionButton';
import { MenuAction } from '../../../../Components/PopupMenu/PopupMenu';
import { isSsoUser } from '../../../../Services/Auth0';

type Props = {
  user: User;
  onOpenResendInvitationModal: (user: User) => void;
  onOpenDeleteUserConfirmationModal: (user: User) => void;
};

export function MoreActions(props: Props): ReactElement | null {
  const { user, onOpenResendInvitationModal, onOpenDeleteUserConfirmationModal } = props;
  const { t } = useTranslation();
  const actions: MenuAction[] = useMemo(() => {
    const actions: MenuAction[] = [];
    if (user.status === UserStatus.invitationPending) {
      actions.push({
        tag: 'MenuAction',
        onClick: () => onOpenResendInvitationModal(user),
        icon: <Icon name='notification' />,
        label: t('cta.sendAReminder'),
      });
    }
    // TODO Allow delete SSO users in the future https://breathelife.atlassian.net/browse/DEV-4953
    if (!isSsoUser({ auth0Id: user.auth0Id })) {
      actions.push({
        tag: 'MenuAction',
        onClick: () => onOpenDeleteUserConfirmationModal(user),
        icon: <Icon name='delete' />,
        label: t('cta.deleteUser'),
      });
    }
    return actions;
  }, [t, onOpenResendInvitationModal, onOpenDeleteUserConfirmationModal, user]);

  if (actions.length <= 0) return null;

  return <MoreActionButton actions={actions}></MoreActionButton>;
}
