import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';

import { useCarrierContext } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { ExportEntityButton } from './ExportEntityButton';

type Props = {
  application?: Application;
};

export function ExportApplicationAnswersButton({ application }: Props): ReactElement {
  const { carrierInfo } = useCarrierContext();

  const carrierName = carrierInfo.companyName.trim().toLowerCase().replace(/\s/, '_');
  const date = dayjs().format('YYYYMMDD_HHmmss');

  const fileName = application
    ? ['answers', carrierName, 'lead', application.leadId, date].filter(Boolean).join('_')
    : '';

  const data = useMemo(() => (application ? JSON.stringify(application.answers, null, 4) : undefined), [application]);

  return <ExportEntityButton name='Export answers' disabled={!application} data={data} fileName={fileName} />;
}
