import { ESignCeremonyStatus, ESignSignerStatus } from '@breathelife/types';

const translation: {
  ceremonyStatus: { [key in ESignCeremonyStatus & 'unknown']: string };
  signerStatus: { [key in ESignSignerStatus & 'notSigned' & 'unknown']: string };
} = {
  ceremonyStatus: {
    [ESignCeremonyStatus.DRAFT]: 'Draft',
    [ESignCeremonyStatus.IN_PROGRESS]: 'Waiting for signature',
    // SENT and READY are also "waiting for signature" because we wouldn't be able to update the status in the
    // ui if it was, e.g. "Generating" -> "Sending" -> "Waiting for Signature". This could be looked into if we
    // start using web sockets.
    [ESignCeremonyStatus.READY]: 'Waiting for signature',
    [ESignCeremonyStatus.SENT]: 'Waiting for signature',
    [ESignCeremonyStatus.COMPLETED]: 'Signed',
    [ESignCeremonyStatus.EMAIL_BOUNCED]: 'Email not delivered',
    [ESignCeremonyStatus.DECLINED]: 'Signature declined',
    [ESignCeremonyStatus.OPT_OUT]: 'Signer opted out of signature',
    [ESignCeremonyStatus.CANCELLED]: 'Signature request cancelled',
    [ESignCeremonyStatus.EXPIRED]: 'Signature request expired',
    [ESignCeremonyStatus.SIGNER_LOCKED_OUT]: 'Signer locked out of signature',
    [ESignCeremonyStatus.SEND_PACKAGE_ERROR]: 'Error sending signature package',
    unknown: 'Unknown Status',
  },
  signerStatus: {
    [ESignSignerStatus.EMAIL_BOUNCED]: 'Email Bounced',
    [ESignSignerStatus.EXPIRED]: 'Expired',
    [ESignSignerStatus.INTERNAL_SIGNING_EMAIL_SENT]: 'Email Sent',
    [ESignSignerStatus.SIGNED]: 'Signed',
    [ESignSignerStatus.SIGNER_LOCKED_OUT]: 'Locked Out',
    notSigned: 'Not signed',
    unknown: 'Unknown Status',
  },
};
export default translation;
