import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import { SubmitResponse } from '@breathelife/crypto-material';
import { StoredFile, QuestionnaireBlueprintCopyableOption } from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { Application } from '../../Models/Application';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { getCoBrowsingAccessLinkToken } from '../../Services/AccessTokenService';
import {
  createApplicationFile,
  CreateApplicationFileData,
  deleteApplicationFile,
} from '../../Services/ApplicationFileService';
import { updateApplication, copyApplication } from '../../Services/ApplicationsService';
import { submitNeedsAnalysis, SubmitNeedsAnalysisResponse } from '../../Services/NeedsAnalysisService';
import { submitInsuranceApplication } from '../../Services/SubmissionService';
import { getTranslationKeyFromErrorId } from '../../Localization/errorIdsToTranslationMap';

export function useCreateApplicationFileMutation(
  options?: UseMutationOptions<StoredFile, AxiosError, CreateApplicationFileData>,
): UseMutationResult<StoredFile, AxiosError, CreateApplicationFileData> {
  const queryClient = useQueryClient();
  // StoredFile = response data
  // unknown 1 = Error
  // CreateApplicationFileData - input param
  // unknown 2 = Context => contextSharing - https://react-query.tanstack.com/reference/QueryClientProvider
  return useMutation<StoredFile, AxiosError, CreateApplicationFileData, unknown>(createApplicationFile, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(QueryId.storedFiles);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });
}

type DeleteApplicationFileParameters = {
  applicationId: string;
  fileId: string;
};

export function useDeleteApplicationFileMutation(
  options?: UseMutationOptions<StoredFile, unknown, DeleteApplicationFileParameters>,
): UseMutationResult<StoredFile, unknown, DeleteApplicationFileParameters> {
  const queryClient = useQueryClient();
  return useMutation<StoredFile, unknown, DeleteApplicationFileParameters, unknown>(
    ({ applicationId, fileId }) => deleteApplicationFile(applicationId, fileId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QueryId.storedFiles);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );
}

type UpdateApplicationIsDeclarationSentParams = {
  applicationId: string;
  isDeclarationSent: boolean;
};

export function useUpdateApplicationIsDeclarationSentMutation(
  options?: UseMutationOptions<Application, unknown, UpdateApplicationIsDeclarationSentParams>,
): UseMutationResult<Application, unknown, UpdateApplicationIsDeclarationSentParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, UpdateApplicationIsDeclarationSentParams>(
    ({ applicationId, isDeclarationSent }) => updateApplication(applicationId, { isDeclarationSent }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.invalidateQueries([QueryId.application, data.id]);
        void queryClient.invalidateQueries([QueryId.lead, data.leadId]);
        void queryClient.invalidateQueries([QueryId.leads]);
        void queryClient.invalidateQueries([QueryId.usedLeadStatuses]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateisDeclarationSent'),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

type SubmitApplicationNeedsAnalysisParams = {
  applicationId: string;
  productId: string;
  coverageAmount: number;
  premium: number;
};

export function useSubmitApplicationNeedsAnalysisMutation(
  options?: UseMutationOptions<SubmitNeedsAnalysisResponse, unknown, SubmitApplicationNeedsAnalysisParams>,
): UseMutationResult<SubmitNeedsAnalysisResponse, unknown, SubmitApplicationNeedsAnalysisParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<SubmitNeedsAnalysisResponse, unknown, SubmitApplicationNeedsAnalysisParams>(submitNeedsAnalysis, {
    ...options,
    onSuccess: async (data, variables, context) => {
      void queryClient.invalidateQueries([QueryId.application, data.application.id]);
      void queryClient.invalidateQueries([QueryId.lead, data.application.leadId]);
      void queryClient.invalidateQueries([QueryId.leads]);
      void queryClient.invalidateQueries([QueryId.usedLeadStatuses]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToSubmitApplicationAndRedirect'),
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

export function useLaunchApplicationCoBrowsingMutation(
  options?: UseMutationOptions<string, unknown, string>,
): UseMutationResult<string, unknown, string> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation<string, unknown, string>(getCoBrowsingAccessLinkToken, {
    ...options,
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToLaunchCoBrowsing'),
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

type CopyApplicationParams = {
  application: Application;
  copyableOptions: QuestionnaireBlueprintCopyableOption[];
};

export function useCopyApplicationMutation(
  options?: UseMutationOptions<Application, unknown, CopyApplicationParams>,
): UseMutationResult<Application, unknown, CopyApplicationParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, CopyApplicationParams>(
    ({ application, copyableOptions }) => copyApplication({ applicationId: application.id, copyableOptions }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.leads]);

        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.copyApplication.copyApplicationSuccess', {
              refNoIncrement: variables.application.refNoIncrement,
              copyRefNoIncrement: data.refNoIncrement,
            }),
            autoHideDuration: 3000,
          }),
        );

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        let errorTranslationKey = getTranslationKeyFromErrorId(error);
        if (!errorTranslationKey) {
          errorTranslationKey = 'notifications.copyApplication.failedToCopyApplication';
        }

        dispatch(
          notificationSlice.actions.setError({
            message: t(errorTranslationKey, {
              refNoIncrement: variables.application.refNoIncrement,
            }),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

export function useSubmitInsuranceApplication(
  options?: UseMutationOptions<
    SubmitResponse | null,
    AxiosError,
    { applicationId: string; productQuote?: number | null }
  >,
): UseMutationResult<SubmitResponse | null, unknown, { applicationId: string; productQuote?: number | null }> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation<SubmitResponse | null, AxiosError, { applicationId: string; productQuote?: number | null }>(
    ({ applicationId, productQuote }) => submitInsuranceApplication(applicationId, productQuote),
    {
      ...options,

      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryId.application, variables.applicationId]);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },

      onError: async (error, variables, context) => {
        if (error instanceof Error) {
          dispatch(
            notificationSlice.actions.setError({
              message: error.message,
            }),
          );

          if (options?.onError) {
            await options.onError(error, variables, context);
          }
        }
      },
    },
  );
}
