import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@breathelife/mui';
import { ApplicationFilters, ApplicationSearchMode, LeadStatus, LeadStatusEnum } from '@breathelife/types';

import { SearchTextField } from '../../../../Components/TextInputs';
import { getCustomizedOptionsFromEnum } from '../../../../Helpers/options';
import { useCarrierContext } from '../../../../Hooks';
import { StyledSearchContainer, StyledSelect } from './Styles';

type ApplicationsTableHeaderProps = {
  onSearchChange: (search: string) => void;
  onSearchModeChange: (searchMode: ApplicationSearchMode) => void;
  onFilterChange: (filter: ApplicationFilters) => void;
};

const leadStatusThatApplyToApplication = [
  LeadStatusEnum.new,
  LeadStatusEnum.transactionFlowStarted,
  LeadStatusEnum.transactionFlowCompleted,
  LeadStatusEnum.qualified,
  LeadStatusEnum.submissionStarted,
  LeadStatusEnum.submissionCompleted,
  LeadStatusEnum.submissionManuallyCompleted,
];

export function ApplicationsTableHeader(props: ApplicationsTableHeaderProps): ReactElement {
  const { onSearchChange, onFilterChange, onSearchModeChange } = props;
  const { t } = useTranslation();
  const { leadStatuses } = useCarrierContext();
  const [selectedStatus, setSelectedStatus] = useState<LeadStatus | ''>('');
  const [selectedSearchMode, setSelectedSearchMode] = useState<ApplicationSearchMode>(
    ApplicationSearchMode.application,
  );

  const onStatusFilterSelected = useCallback(
    (value: LeadStatus | '') => {
      setSelectedStatus(value);
      onFilterChange({
        lead: {
          status: value,
        },
      });
    },
    [selectedStatus],
  );

  const onSearchModeChanged = useCallback(
    (value: ApplicationSearchMode) => {
      setSelectedSearchMode(value);
      onSearchModeChange(value);
    },
    [onSearchModeChange],
  );

  const searchModeOptionsFromEnum = useMemo(
    () =>
      getCustomizedOptionsFromEnum<ApplicationSearchMode>(ApplicationSearchMode, {
        translationBasePath: 'applicationTable.applicationSearchModes',
        customizationMap: Object.keys(ApplicationSearchMode).reduce(
          (customizationMap, searchMode) => ({ ...customizationMap, [searchMode]: { label: searchMode } }),
          {},
        ),
      }),
    [],
  );

  const selectOptionsFromEnum = useMemo(
    () =>
      getCustomizedOptionsFromEnum<LeadStatus>(LeadStatusEnum, {
        translationBasePath: 'leadStatuses',
        customizationMap: leadStatuses,
        withDefaultOption: true,
        defaultOptionLabel: t('leadsListTable.status'),
        whitelist: leadStatusThatApplyToApplication,
      }),
    [leadStatuses],
  );

  return (
    <Box display='flex'>
      <StyledSearchContainer>
        <SearchTextField
          onSearchChange={onSearchChange}
          label={t(`applicationTable.search.${selectedSearchMode}`)}
          fullWidth
        />
      </StyledSearchContainer>
      <StyledSelect
        isSearchFilter
        id='application-search-mode-select'
        value={selectedSearchMode}
        onChange={onSearchModeChanged}
        options={searchModeOptionsFromEnum}
        label={t('applicationTable.searchMode')}
      />
      <StyledSelect
        isSearchFilter
        id='application-lead-status-select'
        value={selectedStatus}
        onChange={onStatusFilterSelected}
        options={selectOptionsFromEnum}
        label={t('applicationTable.filterByLeadStatus')}
      />
    </Box>
  );
}
